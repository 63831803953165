@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        -webkit-tap-highlight-color: transparent;
    }

    body {
        @apply text-gray;
    }

    .text-clip {
        /*noinspection CssInvalidPropertyValue*/
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .conic-green {
        background: linear-gradient(white, rgba(255, 255, 255, 0)), conic-gradient(from .5turn at 50% 110%, #BAFAEF, #5BC4B1);
    }

    .conic-blue {
        background: linear-gradient(white, rgba(255, 255, 255, 0)), conic-gradient(from .5turn at 50% 110%, #B8C9E3, #203155);
    }

    .conic-red {
        background: linear-gradient(white, rgba(255, 255, 255, 0)), conic-gradient(from .5turn at 50% 110%, #FFD1E1, #C22B57);
    }

    .conic-purple {
        background: linear-gradient(white, rgba(255, 255, 255, 0)), conic-gradient(from .5turn at 50% 110%, #E7C1FF, #BC6DEC);
    }

    .tooltip {
        @apply opacity-0 absolute pointer-events-none transition-opacity duration-700 z-50;
    }

    .has-tooltip:hover .tooltip {
        @apply opacity-100 z-50;
    }

    .has-tooltip:hover .tooltip-hoverable {
        @apply pointer-events-auto;
    }

    .tooltip:hover {
        @apply opacity-100 z-50;
    }

    .text-shadow {
        text-shadow: 0 2px 4px rgba(0,0,0,0.20);
    }

    .text-shadow-md {
        text-shadow: 0 4px 8px rgba(0,0,0,0.22), 0 2px 4px rgba(0,0,0,0.28);
    }

    .text-shadow-lg {
        text-shadow: 0 15px 30px rgba(0,0,0,0.21), 0 5px 15px rgba(0,0,0,0.28);
    }

    .text-shadow-none {
        text-shadow: none;
    }

    .scrollbar-thin-2::-webkit-scrollbar-thumb {
        border-left: 20px solid rgba(0, 0, 0, 0);
        border-right: 20px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    .bubbles:before, .bubbles:after {
        position: absolute;
        content: '';
        display: block;
        width: 120%;
        height: 120%;
        z-index: -1000;
        transition: all ease-in-out 0.5s;
        background-repeat: no-repeat;
    }

    .bubbles:before {
        z-index: 60;
        display: none;
        top: -75%;
        background-image: radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, transparent 20%, #5BC4B1 20%, transparent 30%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, transparent 10%, #5BC4B1 15%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%);
        background-size: 0.5rem 0.5rem, 1.5rem 1.5rem, 1rem 1rem, 1.5rem 1.5rem, 1.5rem 1.5rem, 0.5rem 0.5rem, 1rem 1rem, 0.5rem 0.5rem, 1.5rem 1.5rem;
    }

    .bubbles:after {
        z-index: 60;
        display: none;
        bottom: -75%;
        background-image: radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, transparent 10%, #5BC4B1 15%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%), radial-gradient(circle, #5BC4B1 20%, transparent 20%);
        background-size: 1rem 1rem, 1.5rem 1.5rem, 1.5rem 1.5rem, 1.5rem 1.5rem, 1rem 1rem, 0.5rem 0.5rem, 1.5rem 1.5rem;
    }

    .bubbles.animate:before {
        display: block;
        animation: topBubbles ease-in-out 0.75s forwards;
    }

    .bubbles.animate:after {
        display: block;
        animation: bottomBubbles ease-in-out 0.75s forwards;
    }

    @keyframes
    topBubbles {  0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
        50% {
            background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
        }
        100% {
            background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
            background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
        }
    }
    @keyframes
    bottomBubbles {  0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
        50% {
            background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
        }
        100% {
            background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
            background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
        }
    }

    .bubbles {
        position: relative;
    }

    .shadow-lg-primary {
        box-shadow: 0 2px 10px #5BC4B188 !important;
    }

    .shadow-lg-red {
        box-shadow: 0 2px 10px #f8717188 !important;
    }
}


html,
body {
    overscroll-behavior-y: none;
}
